import React, { useState, Children } from 'react'

import { Link, useLocation, useHistory } from 'react-router-dom'

import { IconButton, useMediaQuery } from '@material-ui/core'

import Menu from 'components/common/Menu'
import MenuToolTip from 'components/common/Tooltip'

import Constants from 'Constants'

import { MENU_ITEMS, useFilteredMenuItems } from './menuItems'

import './Style.scss'

const { MEDIA_SERVER } = Constants

const NavigationMenu = () => {
  const location = useLocation()
  const history = useHistory()
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'))
  const { pathname } = location
  const [mobileMenu, setMobileMenu] = useState(null)
  const filteredMenuItems = useFilteredMenuItems()

  const handleNavigate = (event, route) => {
    setMobileMenu(null)
    history.push(route)
  }

  return (
    <div className="Grow Navigation-Menu">
      {isDesktop &&
        Children.toArray(
          filteredMenuItems.map((menuItem, index) => (
            <div className={`Navigation-Item ${pathname === menuItem.route ? 'Active' : ''}`}>
              <Link tabIndex={index + 1} to={menuItem.route} className="Navigation-Label">
                <IconButton tabIndex="-1" color="inherit">
                  {menuItem.icon && (
                    <MenuToolTip title={menuItem.title}>
                      <img edge="start" src={MEDIA_SERVER + menuItem.icon} alt={menuItem.title} className="pr1 Menu-Icon" />
                    </MenuToolTip>
                  )}
                  <span className="Title">{menuItem.title}</span>
                </IconButton>
              </Link>
            </div>
          ))
        )}

      {!isDesktop && (
        <>
          <span className="Title More-Menu ml4 shadow-hover" onClick={event => setMobileMenu(event.currentTarget)}>
            {/* {MORE_MENU} */}
            <img src={`${MEDIA_SERVER}HambugerMenu.svg`} alt="menu" />
          </span>
          {/* Mobile more menu */}
          <Menu
            id="mobile-menu"
            anchorEl={mobileMenu}
            open={Boolean(mobileMenu)}
            menuitems={MENU_ITEMS}
            onClose={() => setMobileMenu(null)}
            onClick={handleNavigate}
          />
        </>
      )}
    </div>
  )
}

export default NavigationMenu

import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import T from 'T'
import { get } from 'lodash'
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from '@mui/material'
import { accordionSummaryClasses } from '@mui/material/AccordionSummary'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { useLazyGetStatementsQuery } from 'api/statements'
import HHAccordion from 'components/common/HHAccordion'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import StatementListItem from './StatementListItem'
import StatementDrawer from './StatementDrawer'
import { groupStatementsByYear } from './settings'

const StatementsList = ({ accountId, businessId }) => {
  const theme = useTheme()
  const shimmersArray = new Array(5).fill(0)

  const [getStatements, { isLoading, isFetching, data }] = useLazyGetStatementsQuery()
  const loading = useMemo(() => isLoading || isFetching, [isLoading, isFetching])

  const [currentStatement, setCurrentStatement] = useState(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const handleStatementClick = statement => {
    setCurrentStatement(statement)
    setIsDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setIsDrawerOpen(false)
    setCurrentStatement(null)
  }

  const groupedStatements = useMemo(() => {
    const statements = get(data, 'statements', [])
    return groupStatementsByYear(statements)
  }, [data])

  const groupedStatementsKeys = useMemo(() => Object.keys(groupedStatements), [groupedStatements])
  const hasGroupedStatements = groupedStatementsKeys.length > 0

  useEffect(() => {
    if (accountId && businessId) {
      getStatements({ businessId, accountId })
    }
  }, [accountId, businessId, getStatements])

  return (
    <>
      <HHAccordion withNewColors isExpanded isExpandCollapseHeightSame summary={`${T.STATEMENT}s`} showActionIcon={false}>
        {loading && (
          <List disablePadding>
            {shimmersArray.map((_, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
                </ListItemIcon>
                <ListItemText primary={<Skeleton variant="text" width={150} />} primaryTypographyProps={{ variant: 'h5' }} />
              </ListItem>
            ))}
          </List>
        )}
        {!loading && !hasGroupedStatements && <HHSectionPlaceholder title={T.NO_STATEMENTS} />}
        {hasGroupedStatements &&
          !loading &&
          groupedStatementsKeys.map(year => {
            const yearStatements = groupedStatements[year]
            const statementsCount = yearStatements.length
            return (
              <Accordion key={year} elevation={0} disableGutters>
                <AccordionSummary
                  expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
                  sx={{
                    flexDirection: 'row-reverse',
                    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
                      transform: 'rotate(90deg)',
                    },
                    [`& .${accordionSummaryClasses.content}`]: {
                      marginLeft: theme.spacing(2),
                    },
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography variant="h5" fontWeight={700}>
                        {year}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" fontWeight={500}>
                        {statementsCount}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <Divider />
                  <List disablePadding>
                    {yearStatements.map(statement => (
                      <StatementListItem key={statement.id} statement={statement} onClick={() => handleStatementClick(statement)} />
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            )
          })}
      </HHAccordion>
      <StatementDrawer
        open={isDrawerOpen}
        accountId={currentStatement?.id}
        statementNumber={currentStatement?.statementNumber}
        onClose={handleDrawerClose}
      />
    </>
  )
}

StatementsList.propTypes = {
  accountId: PropTypes.string.isRequired,
  businessId: PropTypes.string.isRequired,
}

export default StatementsList

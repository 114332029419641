import React, { useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { AppBar, Toolbar, Box, Typography, DialogContent, Card, IconButton, styled, useTheme } from '@mui/material'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import CloseIcon from '@mui/icons-material/Close'
import { Document, Page, pdfjs } from 'react-pdf'
import { Download as UnstyledDownload } from '@styled-icons/heroicons-outline/Download'
import { Printer as UnstyledPrinter } from '@styled-icons/heroicons-outline/Printer'
import Loader from '../../common/loader'
import { printPreviewBase64PDF } from '../../../utils/file'
import HHFullScreenBaseDialog from '../../common/HHFullScreenBaseDialog'
import { HHAlert } from '../../common/HHAlert'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const PDFDocumentWrapper = styled('div')`
  canvas {
    margin-left: auto;
    margin-right: auto;
  }
`

const Download = styled(UnstyledDownload)`
  width: 24px;
  height: 24px;
`

const Printer = styled(UnstyledPrinter)`
  width: 24px;
  height: 24px;
`

const PdfViewer = ({
  isLoading = false,
  fileName,
  title = 'PDF Viewer',
  pdfData,
  open = false,
  type,
  actions,
  onClose = noop,
  onDownload = noop,
}) => {
  const theme = useTheme()
  const [numPages, setNumPages] = useState(undefined)
  const [scale, setScale] = useState(2)

  return (
    <HHFullScreenBaseDialog open={open} onClose={onClose}>
      <AppBar sx={{ backgroundColor: 'background.paper' }} elevation={0}>
        <Toolbar sx={{ backgroundColor: 'background.paper' }} >
          <IconButton
            edge="start"
            onClick={() => {
              setNumPages(undefined)
              setScale(2)
              onClose()
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" color="textPrimary">
            {title}
          </Typography>
          {actions}
        </Toolbar>
      </AppBar>
      {isLoading && <Loader />}
      {!isLoading && pdfData && (
        <DialogContent sx={{ height: 'calc(100vh - 128px)', mt: 8, display: 'block' }}>
          <PDFDocumentWrapper>
            <Document
              file={`data:application/pdf;base64,${pdfData}`}
              loading=""
              onLoadSuccess={({ numPages: nextNumPages }) => {
                setNumPages(nextNumPages)
              }}
            >
              <Box display="block" height="calc(100vh - 64px)">
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} />
                ))}
              </Box>
            </Document>
          </PDFDocumentWrapper>
          <Box
            sx={{
              display: 'block',
              position: 'absolute',
              bottom: '5%',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <Card variant="outlined">
              <Box display="flex" alignItems="center">
                <IconButton
                  disabled={scale === 1.0}
                  onClick={() => {
                    setScale(scale - 0.5)
                  }}
                >
                  <ZoomOutIcon />
                </IconButton>
                <IconButton
                  disabled={scale === 4}
                  onClick={() => {
                    setScale(scale + 0.5)
                  }}
                >
                  <ZoomInIcon />
                </IconButton>
                <IconButton>
                  <Printer onClick={() => printPreviewBase64PDF(pdfData)} />
                </IconButton>
                <IconButton onClick={onDownload} href={`data:application/pdf;base64,${pdfData}`} download={`${fileName}.pdf`}>
                  <Download />
                </IconButton>
              </Box>
            </Card>
          </Box>
        </DialogContent>
      )}
      {!isLoading && !pdfData && (
        <Box display="flex" alignItems="center" justifyContent="center" width="max-content" height="100%" m="0 auto">
          <HHAlert borderColor={theme.palette.error.light} severity="error">
            {`Failed to retrieve ${type}. Please try again.`}
          </HHAlert>
        </Box>
      )}
    </HHFullScreenBaseDialog>
  )
}

PdfViewer.propTypes = {
  isLoading: PropTypes.bool,
  open: PropTypes.bool,
  actions: PropTypes.node,
  title: PropTypes.string,
  fileName: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  pdfData: PropTypes.string,
  type: PropTypes.string.isRequired,
  onDownload: PropTypes.func,
}

export default PdfViewer 
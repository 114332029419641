import { EmailAuthProvider, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider } from 'firebase/auth'

export const PROVIDERS = {
  EMAIL: EmailAuthProvider.PROVIDER_ID,
  GOOGLE: GoogleAuthProvider.PROVIDER_ID,
  FACEBOOK: FacebookAuthProvider.PROVIDER_ID,
  TWITTER: TwitterAuthProvider.PROVIDER_ID,
  APPLE: 'apple.com',
  MICROSOFT: 'microsoft.com',
}

const { EMAIL, GOOGLE, FACEBOOK, TWITTER, MICROSOFT, APPLE } = PROVIDERS

export const isSSOProvider = provider => [GOOGLE, FACEBOOK, TWITTER, MICROSOFT, APPLE].includes(provider)

export const isTrustedSSOProvider = provider => [GOOGLE, TWITTER, MICROSOFT, APPLE].includes(provider)

export const isEmailProvider = provider => provider === EMAIL

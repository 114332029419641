import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getStatements: builder.query({
      query: ({ businessId, accountId }) => ({
        url: `/api/v1/tenants/${businessId}/accounts/${accountId}/statements`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useLazyGetStatementsQuery } = extendedApi 
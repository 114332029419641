import { createSelector } from 'reselect'
import { get } from 'utils/lodash'
import { FEATURE_FLAGS } from 'settings/constants/launchDarkly'

const { STATEMENTS } = FEATURE_FLAGS

const selectFlags = state => get(state, 'flags', {})

export const isFeatureAvailable = (flags, key) => get(flags, key, false)

export const isStatementsFeatureAvailable = createSelector(selectFlags, flags => isFeatureAvailable(flags, STATEMENTS))

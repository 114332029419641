import React from 'react'
import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIcon } from '@mui/material'
import { ReactComponent as PdfIcon } from 'assets/PdfIcon.svg'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import { formatStatementPeriod } from './settings'

const StatementListItem = ({ statement, onClick = noop }) => {
  const formattedPeriod = formatStatementPeriod(statement)

  return (
    <ListItem
      divider
      sx={{
        px: 2,
      }}
      dense
      disableGutters
      disablePadding
    >
      <ListItemButton onClick={onClick}>
        <ListItemIcon sx={{ minWidth: 0, pr: 2 }}>
          <SvgIcon>
            <PdfIcon />
          </SvgIcon>
        </ListItemIcon>
        <ListItemText
          primary={formattedPeriod}
          primaryTypographyProps={{
            variant: 'h6',
            fontWeight: 500,
          }}
        />
      </ListItemButton>
      <Box height={36} width={36} />
    </ListItem>
  )
}

StatementListItem.propTypes = {
  statement: PropTypes.object,
  onClick: PropTypes.func,
}

export default StatementListItem

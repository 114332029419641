import React from 'react'
import PropTypes from 'prop-types'

import { Box, Alert, Typography } from '@mui/material'

const HHSectionPlaceholder = ({ title, sx, ...rest }) => {
  return (
    <Box p={2}>
      <Alert sx={{ display: 'flex', alignItems: 'center', ...sx }} severity="info" {...rest}>
        <Typography variant="h6" fontWeight={400}>
          {title}
        </Typography>
      </Alert>
    </Box>
  )
}

HHSectionPlaceholder.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  sx: PropTypes.object,
}

export default HHSectionPlaceholder 
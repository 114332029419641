import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'

import { Box } from '@material-ui/core'
import { UserCircle } from '@styled-icons/heroicons-outline/UserCircle'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined'
import { Login } from '@styled-icons/heroicons-outline/Login'

import { get } from 'utils/lodash'
import { decodeToken } from 'utils/token'
import { handleFirebaseLogout } from 'providers/firebaseAuth'
import { useAuthValue } from 'auth/AuthContext'
import { isEmailProvider } from 'settings/constants/auth'

import routes from 'router/routes'
import Menu from 'components/common/Menu'
import Endpoint from 'middleware/sagas/endpoint'
import T from 'T'

import './User.scss'

const { profile: CDNUrl } = Endpoint.user

const User = ({ firstName, lastName, profilePicture, enableClick, isCDN }) => {
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  // currentUser.providerData returns all strategies, so decoding the token to get the strategy used
  const token = get(useAuthValue(), 'currentUser.accessToken')
  const decodedToken = decodeToken(token)
  const isPasswordProvider = isEmailProvider(get(decodedToken, 'firebase.sign_in_provider'))

  // menuItems on click of user profile pic
  const menuitems = [
    { ...(isPasswordProvider && { title: T.SETTINGS, icon: <SettingsOutlinedIcon /> }) },
    { title: T.PRIVACY_POLICY, icon: <PolicyOutlinedIcon />, type: 'link', url: 'https://www.haulerhero.com/terms' },
    { title: T.LOGOUT, icon: <Login /> },
  ].filter(value => Object.keys(value).length !== 0)

  // close menu function
  const handleClose = () => setAnchorEl(null)

  // on profile pic click_event
  const handlePersonButtonClicked = event => setAnchorEl(event.currentTarget)

  // handle click of user menus
  const handleClick = e => {
    const selectedMenu = e.target.innerText

    if (selectedMenu === T.LOGOUT) {
      handleFirebaseLogout()
    } else {
      handleClose()
      history.push(routes.app.settings)
    }
  }

  return (
    <>
      <div className="profile-btn" onClick={e => enableClick && handlePersonButtonClicked(e)}>
        <div>
          {profilePicture ? (
            <img src={`${isCDN ? CDNUrl : profilePicture}`} alt="profile pic" className="profile-pic" />
          ) : (
            <Box className="profile-pic-with-initials">
              {firstName && firstName.charAt(0)}
              {lastName && lastName.charAt(0)}

              {!firstName && !lastName && <UserCircle className="mb-4" />}
            </Box>
          )}
        </div>
      </div>

      {/* menu component call */}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        menuitems={menuitems}
        onClose={handleClose}
        onClick={handleClick}
      />
    </>
  )
}

User.defaultProps = {
  firstName: '',
  lastName: '',
  profilePicture: '',
  isCDN: true,
  enableClick: true,
}

User.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  enableClick: PropTypes.bool,
  isCDN: PropTypes.bool,
  profilePicture: PropTypes.string,
}

export default User

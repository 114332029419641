import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { useTheme, Dialog, Slide } from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const APPBAR_SX = { backgroundColor: '#F7F9FC', borderBottom: '2px solid', borderBottomColor: 'primary.main' }

const HHFullScreenBaseDialog = ({ children, onClose = noop, sx, ...rest }) => {
  const theme = useTheme()
  return (
    <Dialog
      {...rest}
      onClose={onClose}
      fullScreen
      TransitionComponent={Transition}
      sx={{ zIndex: theme.zIndex.snackbar, '& header': { ...APPBAR_SX, zIndex: theme.zIndex.snackbar }, ...sx }}
    >
      {children}
    </Dialog>
  )
}

HHFullScreenBaseDialog.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  sx: PropTypes.object,
}

export default HHFullScreenBaseDialog 
import React, { useEffect } from 'react'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useSearchParams } from 'react-router-dom-v5-compat'

import { withStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'

import { get } from 'utils/lodash'
import { getHomePagePayments } from 'middleware/actions/payments'

import Greeting from 'components/common/Greeting'
import BillingSection from 'components/home/BillingSection'
import ConfigureServices from 'components/home/ConfiguredServices'
import PaymentSection from 'components/home/PaymentSection'

import './Home.scss'
import './ListToolbar.scss'

const styles = () => ({})

const Home = () => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const businessId = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.businessId'), shallowEqual)
  const accountId = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.accountId'), shallowEqual)
  const firstName = useSelector(state => get(state, 'AuthReducer.userInfo.firstName', ''), shallowEqual)

  useEffect(() => {
    if (businessId && accountId) {
      dispatch(getHomePagePayments({ businessId, accountId }))
    }
  }, [businessId, accountId])

  useEffect(() => {
    setSearchParams({})
  }, [])

  return (
    <Grid container spacing={2} className="home-page-container">
      <Grid item xs={12}>
        <Greeting name={firstName} />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={3}>
        <BillingSection />
        <PaymentSection />
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={9}>
        <ConfigureServices />
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Home)

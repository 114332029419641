import React, { useEffect, useReducer } from 'react'
import { store } from 'providers/store'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import { get } from 'utils/lodash'
import { handleReducer } from 'utils/helper'
import { getSignup } from 'middleware/actions/signup'
import { putErrorResponse } from 'middleware/actions/response'

import Constants from 'Constants'
import AccountAlready from 'containers/signup/AccountAlready'

import ThankYou from 'containers/signup/ThankYou'
import Loader from 'components/common/loader'

import T from 'T'

import './signup.scss'

const { MEDIA_SERVER } = Constants

const SignUp = props => {
  const dispatch = useDispatch()
  const portalUrl = window.location.origin + '/app/setup-account'

  const { signup, errorResponse, isLoading } = useSelector(
    storeVal => ({
      signup: storeVal.SignupReducer.signup,
      errorResponse: storeVal.ResponseReducer.errorResponse,
      isLoading: get(storeVal, 'ResponseReducer.isLoading', false),
    }),
    shallowEqual
  )

  const initialState = {
    emailAddress: '',
    // firstName: '',
    // lastName: '',
    accountNumber: '',
    billingZipCode: '',
    isSubmitted: false,
    isError: false,
    error: '',
    businessName: null,
    isBusinessNameError: false,
    isAccountNumberError: false,
    emptyFields: false,
  }

  const [state, setState] = useReducer(handleReducer, initialState)

  useEffect(() => {
    if (signup && signup.success) {
      setState({ isSubmitted: true })
    } else if (signup && !signup.success) {
      setState({ isSubmitted: false, isError: true, isBusinessNameError: true, isAccountNumberError: true, emptyFields: false })
    } else {
      setState({ isSubmitted: false, isError: false })
    }
  }, [signup])

  const clearErrorMsg = () => {
    store.dispatch(putErrorResponse(''))
    setState({ isError: false, isBusinessNameError: false, isAccountNumberError: false })
  }

  useEffect(() => {
    clearErrorMsg()
  }, [])

  useEffect(() => {
    if (errorResponse !== '') {
      setState({ isError: true, isBusinessNameError: true, isAccountNumberError: true, emptyFields: false })
    }
  }, [errorResponse !== ''])

  useEffect(() => {
    let searchVal = props?.history?.location?.search?.substr(5)

    if (searchVal && searchVal !== 'null') {
      setState({ businessName: searchVal })
    }

    setState({ emptyFields: false, isError: false })
  }, [props.history])

  const handleSignup = () => {
    const { emailAddress, accountNumber, billingZipCode, businessName } = state

    if (!emailAddress || !accountNumber || !billingZipCode) {
      setState({ emptyFields: true })

      return
    }

    const payload = {
      accountNumber,
      businessName,
      email: emailAddress.toLowerCase(),
      // firstName,
      // lastName,
      portalUrl,
      zipCode: billingZipCode,
    }

    dispatch(getSignup(payload))
  }

  const addErrorClass = name => {
    if (state.isError && state[name]) return 'input-error'
    return ''
  }

  return (
    <div className="signup-wrapper flex flex-column items-center justify-center" onClick={() => clearErrorMsg()}>
      {isLoading && <Loader />}
      <div className="flex flex-column">
        <div className="flex w-100 justify-center">
          <img src={`${MEDIA_SERVER}HeaderLogo-Black-200.png`} alt="Hauler Hero Customer Portal Sign up" />
        </div>

        <div className={`signup-form-wrapper flex flex-column ${state.isSubmitted ? 'justify-center items-center' : 'justify-start'}`}>
          {!state.isSubmitted ? (
            <>
              <span className="title">{T.SIGNUP_FOR_ACCOUNT}</span>

              {state.isError && (
                <div className="sign-up-error-msg cursor-pointer">
                  System unable to generate an account. Some possible reasons are:
                  <List dense>
                    <ListItem>* some information you provided is incorrect or not in our system</ListItem>
                    <ListItem>* an account exist with this email address</ListItem>
                  </List>
                  Please check and try again.
                </div>
              )}
              {state.emptyFields && (
                <div className="sign-up-error-msg justify-center cursor-pointer" onClick={() => setState({ emptyFields: false })}>
                  All fields are mandatory
                </div>
              )}

              {/* <div className="input-wrapper flex flex-column">
                <label htmlFor="firstName">{T.FIRSTNAME}</label>
                <input
                  type="text"
                  placeholder={T.FIRSTNAME}
                  value={state.firstName}
                  onChange={evt => setState({ firstName: evt.target.value })}
                />
              </div>

              <div className="input-wrapper flex flex-column">
                <label htmlFor="lastName">{T.LASTNAME}</label>
                <input
                  type="text"
                  placeholder={T.LASTNAME}
                  value={state.lastName}
                  onChange={evt => setState({ lastName: evt.target.value })}
                />
              </div> */}

              <div className="input-wrapper flex flex-column">
                <label htmlFor="emailAddress">{T.EMAIL_ADDRESS}</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder={T.EMAIL_ADDRESS}
                  value={state.emailAddress}
                  onChange={evt => setState({ emailAddress: evt.target.value })}
                />
              </div>

              <div className="input-wrapper flex flex-column">
                <label htmlFor="accountNumber">{T.ACCOUNT_NUMBER}</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder={T.ACCOUNT_NUMBER}
                  value={state.accountNumber}
                  className={addErrorClass('isAccountNumberError')}
                  onChange={evt => setState({ accountNumber: evt.target.value })}
                />
              </div>

              <div className="input-wrapper flex flex-column">
                <label htmlFor="billingZipCode">{T.BILLING_ZIP_CODE}</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder={T.BILLING_ZIP_CODE}
                  value={state.billingZipCode}
                  onChange={evt => setState({ billingZipCode: evt.target.value, isBusinessNameError: false })}
                  className={addErrorClass('isBusinessNameError')}
                />
              </div>

              <div className="signup-button-wrapper flex w-100">
                <button className="signup-button w-100" onClick={handleSignup} disabled={isLoading}>
                  {T.SIGN_UP}
                </button>
              </div>
            </>
          ) : (
            <ThankYou />
          )}
        </div>

        {!state.isSubmitted && <AccountAlready />}
      </div>
    </div>
  )
}

export default SignUp

import React, { useEffect, useReducer, useState } from 'react'

import { toast } from 'react-toastify'
import { SvgIcon } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { Facebook, Instagram, Twitter } from '@material-ui/icons'
import { Alert, Button } from '@mui/material'

import { get } from 'utils/lodash'
import { handleReducer } from 'utils/helper'
import { getAddPassword, getEmailVerification } from 'middleware/actions/signup'

import Constants from 'Constants'
import Routes from 'router/routes'
import AccountAlready from 'containers/signup/AccountAlready'
import Loader from 'components/common/loader'

import './signup.scss'
import T from 'T'

const { MEDIA_SERVER } = Constants

const SetupAccount = props => {
  const dispatch = useDispatch()
  const [passwordErr, setPasswordErr] = useState(false)
  const [newPasswordErr, setNewPasswordErr] = useState(false)

  const initialState = {
    password: '',
    confirmPassword: '',
    requestId: null,
    username: null,
    accountNumber: null,
    billingZipCode: null,
    passwordComplexity: {},
    isDataConfirmed: true,
    isLengthError: false,
    passwordErrMessage: '',
    isLoading: false,
    hasValidToken: false,
    hasError: false,
  }

  const [state, setState] = useReducer(handleReducer, initialState)
  const { isLoading, hasValidToken, hasError } = state

  useEffect(() => {
    let searchVal = props?.history?.location?.search?.substr(5)

    if (searchVal) {
      setState({ requestId: searchVal })
    }
  }, [props.history])

  useEffect(() => {
    if (state.requestId) {
      setState({ isLoading: true })
      dispatch(
        getEmailVerification({ requestId: state.requestId }, res => {
          setState({
            isLoading: false,
            hasValidToken: res?.username,
            username: get(res, 'username', ''),
            accountNumber: get(res, 'accountNumber', ''),
            billingZipCode: get(res, 'zipCode', ''),
            passwordComplexity: get(res, 'passwordComplexity', {}),
          })
        })
      )
    }
  }, [state.requestId])

  const handleCreateAccount = () => {
    const { password, confirmPassword } = state

    if (!confirmPassword || !password) {
      toast.error('All fields are required.')
      return
    }

    if (password.length < state.passwordComplexity.minLength) {
      setState({ isLengthError: true, passwordErrMessage: T.PASSWORD_LENGTH_MSG })
    } else {
      setState({ isLengthError: false, passwordErrMessage: '' })
    }

    if (confirmPassword !== password) {
      setPasswordErr(true)
      setNewPasswordErr(true)
      setState({ passwordErrMessage: T.NEW_PWD_DO_NOT_MATCH })
      return
    } else {
      setPasswordErr(false)
      setNewPasswordErr(false)
      setState({ passwordErrMessage: '' })
    }

    setState({ isLoading: true, hasError: false })
    const payload = {
      requestId: state.requestId,
      password: state.password,
    }

    dispatch(
      getAddPassword(payload, res => {
        setState({ isLoading: false })
        if (res?.success) {
          props.history.push(Routes.app.home)
          return
        }
        setState({ hasError: true })
      })
    )
  }

  const checkInputError = () => {
    if (state.password.length < state.passwordComplexity.minLength) {
      setState({ isLengthError: true, passwordErrMessage: T.PASSWORD_LENGTH_MSG })
    } else {
      setState({ isLengthError: false, passwordErrMessage: '' })
    }
  }

  return (
    <div className="signup-wrapper flex flex-column items-center justify-center">
      {isLoading && <Loader />}
      {!state.isDataConfirmed && (
        <div className="flex flex-column items-center">
          <div className="setup-form-wrapper">
            <div className="flex w-100 justify-center">
              <img src={`${MEDIA_SERVER}HeaderLogo-Black-200.png`} alt="Hauler Hero Customer Portal Sign up" />
            </div>
            <p className="thank-you-msg">{T.THANK_YOU_FOR_SIGNING_UP}</p>
            <hr />

            <div className="form-data-wrapper flex flex-column w-100">
              <div className="flex">
                <label>{T.EMAIL_ADDRESS}</label>
                <span className="user-data">{state.username}</span>
              </div>

              <div className="flex">
                <label>{T.ACCOUNT_NUMBER}</label>
                <span className="user-data">{state.accountNumber}</span>
              </div>

              <div className="flex">
                <label>{T.BILLING_ZIP_CODE}</label>
                <span className="user-data">{state.zipCode}</span>
              </div>

              <div className="signup-button-wrapper flex">
                <button className="signup-button w-100" onClick={() => setState({ isDataConfirmed: true })}>
                  {T.CONFIRM_ACCOUNT}
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-column setup-footer justify-center items-center w-100">
            <div className="footer-social-media flex justify-between">
              <a href="">
                <SvgIcon>
                  <Twitter />
                </SvgIcon>
              </a>

              <a href="">
                <SvgIcon>
                  <Facebook />
                </SvgIcon>
              </a>

              <a href="">
                <SvgIcon>
                  <Instagram />
                </SvgIcon>
              </a>
            </div>

            <a href="https://www.haulerhero.com">www.haulerhero.com</a>

            <span>
              &copy; {new Date().getFullYear()} {T.HAULER_HERO}
            </span>
          </div>
        </div>
      )}

      {state.isDataConfirmed && (
        <div className="flex flex-column">
          <div className="flex w-100 justify-center">
            <img src={`${MEDIA_SERVER}HeaderLogo-Black-200.png`} alt="Hauler Hero Customer Portal Sign up" />
          </div>

          <div className="signup-form-wrapper flex flex-column justify-start">
            <span className="title">{T.SET_UP_YOUR_ACCOUNT}</span>

            {(!hasValidToken || hasError) && !isLoading && (
              <Alert severity="error" sx={{ mb: 3, alignItems: 'center' }}>
                {hasError ? T.ACCOUNT_CREATE_ERROR : T.USER_CANNOT_BE_FOUND}
              </Alert>
            )}

            {(passwordErr || newPasswordErr || state.isLengthError) && state.passwordErrMessage && (
              <Alert severity="error" sx={{ alignItems: 'center' }}>
                {state.passwordErrMessage}
              </Alert>
            )}

            <div className="input-wrapper flex flex-column">
              <label htmlFor="password">{T.PASSWORD}</label>
              <input
                type="password"
                autoComplete="off"
                placeholder={T.PASSWORD}
                className={state.isLengthError ? 'input-error' : ''}
                onBlur={() => checkInputError()}
                value={state.password}
                onChange={evt => setState({ password: evt.target.value })}
              />
              {state.passwordComplexity && (
                <div className="flex complexity">
                  <span className="complexity-point" />
                  <span className="complexity-text">Minimum {state.passwordComplexity.minLength} characters</span>
                </div>
              )}
            </div>

            <div className="input-wrapper flex flex-column">
              <label htmlFor="reEnterPassword">{T.RENTER_PASSWORD}</label>
              <input
                type="password"
                autoComplete="off"
                placeholder={T.RENTER_PASSWORD}
                value={state.confirmPassword}
                onChange={evt => setState({ confirmPassword: evt.target.value })}
              />
            </div>

            <div className="signup-button-wrapper flex w-100">
              <Button className="signup-button w-100" disabled={!hasValidToken} onClick={handleCreateAccount}>
                {T.CREATE_ACCOUNT}
              </Button>
            </div>
          </div>
          <AccountAlready />
        </div>
      )}
    </div>
  )
}

export default SetupAccount

import chunk from 'lodash/chunk'
import { getApp } from 'firebase/app'
import { getBlob, getStorage, ref, getMetadata } from 'firebase/storage'
import { store } from 'providers/store'
import { putIsLoading } from 'middleware/actions/response'
import Constants from '../Constants'
import { get } from './lodash'
import { cloudStorageErrorHandler } from './error'

const { ASSETS_BUCKET_NAME } = Constants

const DOWNLOAD_TYPE = {
  pdf: 'application/pdf',
}

export const downloadFileOld = (data, fileName, type = 'pdf') => {
  const linkSource = `data:application/${type};base64,${data}`
  const downloadLink = document.createElement('a')
  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
}

export const downloadFile = (data, fileName, type = 'pdf') => {
  const url = window.URL.createObjectURL(new Blob([data], { type: get(DOWNLOAD_TYPE, type) }))
  // Create A label
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  // Set the download file file name
  link.setAttribute('download', fileName) // or any other extension
  console.log(fileName)
  document.body.appendChild(link)
  link.click()
}

export const blobToBase64 = blob => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export const requestBlobFromUrl = url => {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'

    xhr.addEventListener('load', () => {
      const blob = xhr.response
      resolve(blob)
    })
    xhr.open('GET', url)
    xhr.send()
  })
}

export const getFileBlob = file => {
  const firebaseApp = getApp()
  const storage = getStorage(firebaseApp, `gs://${ASSETS_BUCKET_NAME}`)
  const fileRef = ref(storage, file)
  return getBlob(fileRef)
}

export const getInvoiceBlob = (invoiceNumber, businessId) => getFileBlob(`${businessId}/INVOICE/${invoiceNumber.toString()}.pdf`)

export const getStatementBlob = (statementNumber, businessId) => getFileBlob(`${businessId}/STATEMENT/${statementNumber}.pdf`)

export const downloadInvoiceBlob = (invoiceNumber, businessId, showLoader) => {
  if (showLoader) store.dispatch(putIsLoading(true))

  getInvoiceBlob(invoiceNumber, businessId)
    .then(blob => downloadFile(blob, invoiceNumber))
    .catch(cloudStorageErrorHandler)
    .finally(() => {
      if (showLoader) store.dispatch(putIsLoading(false))
    })
}

export const downloadInvoiceBlobListInChunks = async (invoiceNumberList, businessId, showLoader) => {
  if (showLoader) store.dispatch(putIsLoading(true))

  const invoiceNumberDonwloadListOfChunks = chunk(invoiceNumberList, 2)
  for (const invoiceNumberChunkList of invoiceNumberDonwloadListOfChunks) {
    const dowloadBlobInvoiceList = invoiceNumberChunkList.map(invoiceNumber => downloadInvoiceBlob(invoiceNumber, businessId))
    await Promise.all(dowloadBlobInvoiceList)
    await new Promise(r => setTimeout(r, 500))
  }

  if (showLoader) store.dispatch(putIsLoading(false))
}

const getPaymentReceiptURL = (businessId, accountId, accountTransactionHistoryId) =>
  `${businessId}/accounts/${accountId}/payments/${accountTransactionHistoryId.toString()}.pdf`

export const getPaymentReceiptBlob = (businessId, accountId, accountTransactionHistoryId) =>
  getFileBlob(getPaymentReceiptURL(businessId, accountId, accountTransactionHistoryId))

export const downloadPaymentReceiptBlob = (businessId, accountId, paymentNumber, accountTransactionHistoryId, showLoader) => {
  if (showLoader) store.dispatch(putIsLoading(true))

  getPaymentReceiptBlob(businessId, accountId, accountTransactionHistoryId)
    .then(blob => downloadFile(blob, paymentNumber))
    .catch(cloudStorageErrorHandler)
    .finally(() => {
      if (showLoader) store.dispatch(putIsLoading(false))
    })
}

export const checkPaymentReceiptPDFExists = (businessId, accountId, accountTransactionHistoryId) => {
  const firebaseApp = getApp()
  const storage = getStorage(firebaseApp, `gs://${ASSETS_BUCKET_NAME}`)

  const fileRef = ref(storage, getPaymentReceiptURL(businessId, accountId, accountTransactionHistoryId))
  return getMetadata(fileRef)
    .then(() => Promise.resolve(true))
    .catch(() =>
      // we can also use error.code === 'storage/object-not-found'
      Promise.resolve(false)
    )
}

export const convertBase64toBlob = (base64Data, contentType = '') => {
  const sliceSize = 1024
  const byteCharacters = atob(base64Data)
  const bytesArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const bytes = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      bytes[i] = slice.charCodeAt(i)
    }
    const byteArray = new Uint8Array(bytes)
    bytesArrays.push(byteArray)
  }
  return new Blob(bytesArrays, { type: contentType })
}

export const printPreviewBase64PDF = (data, type = 'application/pdf') => {
  let blob = null
  blob = convertBase64toBlob(data, type)
  const blobURL = URL.createObjectURL(blob)
  const windowRef = window.open(blobURL)
  const docRef = windowRef.document
  const scriptRef = document.createElement('script')

  function injectThis() {
    window.print()
  }

  scriptRef.innerHTML = `window.onload = ${injectThis.toString()};`
  docRef.body.appendChild(scriptRef)
}

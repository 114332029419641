/* eslint-disable */
console.log(`Mode => ${process.env.REACT_APP_MODE}`)
console.log(`Auth Server => ${process.env.REACT_APP_AUTH_SERVER}`)
const Constants = {
  SERVER_URL: window.location.origin,
  SERVER_PUBLIC_URL: process.env.REACT_APP_BACKEND_API_PUBLIC_BASE_URL,
  MEDIA_SERVER: 'https://media.haulerhero.com/',
  AUTH_SERVER: process.env.REACT_APP_AUTH_SERVER,
  DEV_MODE: process.env.REACT_APP_MODE === 'dev',
  REDIRECT_KEY: 'redirectPath',
  ASSETS_BUCKET_NAME: process.env.REACT_APP_ASSETS_BUCKET_NAME,
  PUBLIC_ASSETS_BUCKET_NAME: process.env.REACT_APP_PUBLIC_ASSETS_BUCKET_NAME,
  STORAGE_PATH: 'https://storage.googleapis.com/',
  MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_TOKEN,
  PORTAL_AUTH_TENANT_ID: process.env.REACT_APP_PORTAL_AUTH_TENANT_ID,
  LAUNCH_DARKLY_CLIENT_SIDE_ID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
}

export default Constants

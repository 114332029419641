import React from 'react'
import ReactDOM from 'react-dom'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import App from 'containers/app/App'
import Constants from 'Constants'
import reportWebVitals from './reportWebVitals'

import './index.css'

const { LAUNCH_DARKLY_CLIENT_SIDE_ID } = Constants

;(async () => {
  const LDProvider = await asyncWithLDProvider({ clientSideID: LAUNCH_DARKLY_CLIENT_SIDE_ID })

  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import React from 'react'
import PropTypes from 'prop-types'
import StatementsList from 'components/statements/StatementsList'

const Statements = ({ accountId, businessId }) => {
  return <StatementsList accountId={accountId} businessId={businessId} />
}

Statements.propTypes = {
  accountId: PropTypes.string.isRequired,
  businessId: PropTypes.string.isRequired,
}

export default Statements 
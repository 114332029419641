import * as React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import { styled } from '@mui/material/styles'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import IconButton from '@mui/material/IconButton'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { PencilAlt } from '@styled-icons/heroicons-outline/PencilAlt'
import { Plus } from '@styled-icons/heroicons-outline/Plus'
import { Box } from '@mui/material'
import { MoreVert } from '@mui/icons-material'

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} {...props} />)(({ theme, ...props }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.border.secondary}`,
  boxSizing: 'border-box',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.shape.borderRadius + 2,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-root.Mui-expanded': {
    minHeight: theme.spacing(props?.isExpandCollapseHeightSame ? 6 : 5),
  },
}))

export const AccordionSummary = styled(props => (
  <MuiAccordionSummary direction="row-reverse" expandIcon={<ArrowRightIcon sx={{ fontSize: '1.5rem', color: '#afb4bd' }} />} {...props} />
))(({ theme, withNewColors = false }) => ({
  backgroundColor: withNewColors ? theme.palette.background.header : theme.palette.background.accordion,
  borderRadius: `${theme.shape.borderRadius + 2}px ${theme.shape.borderRadius + 2}px 0 0`,
  height: theme.spacing(5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderBottomColor: theme.palette.border.secondary,
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '&.Mui-focusVisible': {
    backgroundColor: withNewColors ? theme.palette.background.header : theme.palette.background.accordion,
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
  borderRadius: 1.5,
  display: 'block',
}))

const ACTION_TO_ICON_MAP = {
  edit: PencilAlt,
  add: Plus,
  ellipsis: MoreVert,
}

const HHAccordion = ({
  showActionIcon = true,
  summary = 'Title',
  actionType = 'edit',
  onActionClick = noop,
  isExpanded = false,
  isExpandCollapseHeightSame = false,
  children,
  withNewColors = false,
  showCustomActions = false,
  customActionContent,
  ...rest
}) => {
  const [expanded, setExpanded] = React.useState(isExpanded)
  const ActionIcon = actionType in ACTION_TO_ICON_MAP ? ACTION_TO_ICON_MAP[actionType] : Plus
  return (
    <Accordion
      isExpandCollapseHeightSame={isExpandCollapseHeightSame}
      expanded={expanded}
      onChange={() => setExpanded(isOpen => !isOpen)}
      {...rest}
    >
      <AccordionSummary withNewColors={withNewColors}>
        <Typography variant="h4" color="text.primary" fontSize={14}>
          {summary}
        </Typography>
        {showActionIcon && (
          <IconButton
            sx={{ color: 'text.secondary' }}
            onClick={e => {
              e.stopPropagation()
              onActionClick(e)
            }}
          >
            <ActionIcon width={20} height={20} />
          </IconButton>
        )}
        {showCustomActions && customActionContent}
      </AccordionSummary>
      <AccordionDetails>
        <Box display="block" width="100%">
          {children}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

HHAccordion.propTypes = {
  showActionIcon: PropTypes.bool,
  actionType: PropTypes.string,
  summary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onActionClick: PropTypes.func,
  isExpanded: PropTypes.bool,
  isExpandCollapseHeightSame: PropTypes.bool,
  children: PropTypes.node,
  withNewColors: PropTypes.bool,
  showCustomActions: PropTypes.bool,
  customActionContent: PropTypes.node,
}

export default HHAccordion 
import React from 'react'

import Constants from 'Constants'
import Routes from 'router/routes'

import T from 'T'

const { MEDIA_SERVER } = Constants

const AccountAlready = () => {
  const defaultURL = window.location.origin + '' + Routes.app.home

  return (
    <div className="flex w-100 justify-center already-account-wrapper">
      <span>{T.ALREADY_HAVE_ACCOUNT} &nbsp;</span>
      <a href={`${Constants.AUTH_SERVER}?callback=${defaultURL}`}>{T.SIGN_IN}</a>
    </div>
  )
}

export default AccountAlready

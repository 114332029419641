import React, { useState } from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { FacebookAuthProvider, signInWithPopup, fetchSignInMethodsForEmail } from 'firebase/auth'
import { Box, Button, useTheme, Alert, Typography } from '@mui/material'

import { get } from 'utils/lodash'
import { isTrustedSSOProvider } from 'settings/constants/auth'

import Constants from 'Constants'

const { MEDIA_SERVER } = Constants

const FacebookLogin = ({ auth, onRegisterUser }) => {
  const theme = useTheme()
  const [error, setError] = useState({ providerId: '', errMessage: '' })
  const { errMessage } = error

  const handleLoginWithFacebook = async () => {
    try {
      const authResult = await signInWithPopup(auth, new FacebookAuthProvider())
      const { user, _tokenResponse } = authResult
      onRegisterUser({
        user,
        additionalUserInfo: {
          providerId: get(_tokenResponse, 'providerId'),
          profile: {
            firstName: get(_tokenResponse, 'firstName', ''),
            lastName: get(_tokenResponse, 'lastName', ''),
          },
        },
      })
    } catch (err) {
      if (err.code === 'auth/account-exists-with-different-credential') {
        const providers = await fetchSignInMethodsForEmail(auth, get(err, 'customData.email'))
        const firstProviderMethod = providers.find(provider => isTrustedSSOProvider(provider))
        if (firstProviderMethod) {
          const providerName = capitalize(firstProviderMethod.replace('.com', ''))
          setError({
            providerId: firstProviderMethod,
            errMessage: `You already have an account with ${providerName}. Please sign in with ${providerName} to continue.`,
          })
          return
        }
        setError({ providerId: '', errMessage: "Your account is linked to a provider that isn't supported." })
      }
    }
  }

  return (
    <>
      {errMessage && (
        <Alert severity="error" sx={{ mx: 3, mb: 2, alignItems: 'center' }}>
          <Typography variant="h6" fontWeight={400}>
            {errMessage}
          </Typography>
        </Alert>
      )}
      <Box position="relative" id="custom-auth-container">
        <Button
          onClick={handleLoginWithFacebook}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 0,
            minWidth: 48,
            width: 48,
            height: 48,
            boxShadow: 'none',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '50%',
            position: 'absolute',
            zIndex: theme.zIndex.appBar,
            left: '61.5%',
          }}
        >
          <img alt="" width={24} height={24} src={`${MEDIA_SERVER}social-icons/auth/Facebook.svg`} />
        </Button>
      </Box>
    </>
  )
}

FacebookLogin.propTypes = {
  auth: PropTypes.object,
  onRegisterUser: PropTypes.func.isRequired,
}

export default FacebookLogin

import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { get } from 'lodash'

import PageHeader from 'components/common/PageHeader'
import Statements from 'containers/statements'
import T from 'T'

const StatementsPage = () => {
  const businessId = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.businessId'), shallowEqual)
  const accountId = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.accountId'), shallowEqual)

  return (
    <section className="common-padding page-bg">
      <div className="invoice-heading">
        <PageHeader title={`${T.STATEMENT}s`} />
      </div>
      <Statements accountId={accountId} businessId={businessId} />
    </section>
  )
}

export default StatementsPage 
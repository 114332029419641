import { endOfMonth, endOfQuarter, endOfYear, format, getQuarter, parseISO, startOfMonth, startOfQuarter, startOfYear } from 'date-fns'
import { orderBy, groupBy } from 'lodash'

const generateMonthlyBillingPeriods = earliestDate => {
  const result = []
  const currentDate = new Date()
  const startDate = parseISO(earliestDate)
  const startYear = startDate.getFullYear()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1

  for (let year = startYear; year <= currentYear; year++) {
    for (let month = 1; month <= 12; month++) {
      if (year === startYear && month < startDate.getMonth() + 1) continue
      if (year === currentYear && month > currentMonth) break

      const periodStartDate = startOfMonth(new Date(year, month - 1, 1))
      const periodEndDate = endOfMonth(periodStartDate)

      result.push({
        label: format(periodStartDate, 'MMMM yyyy'),
        startDate: format(periodStartDate, 'yyyy-MM-dd'),
        endDate: format(periodEndDate, 'yyyy-MM-dd'),
        period: `${format(periodStartDate, 'MMM d')} - ${format(periodEndDate, 'd')}`,
      })
    }
  }
  return result
}

const generateQuarterlyBillingPeriods = earliestDate => {
  const result = []
  const currentDate = new Date()
  const startDate = parseISO(earliestDate)
  const startYear = startDate.getFullYear()
  const startQuarter = getQuarter(startDate)
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1

  for (let year = startYear; year <= currentYear; year++) {
    for (let quarter = 1; quarter <= 4; quarter++) {
      if (year === startYear && quarter < startQuarter) continue
      const month = (quarter - 1) * 3 + 1
      if (year === currentYear && month > currentMonth) break

      const periodStartDate = startOfQuarter(new Date(year, month - 1, 1))
      const periodEndDate = endOfQuarter(periodStartDate)

      result.push({
        label: `Q${quarter} ${year}`,
        startDate: format(periodStartDate, 'yyyy-MM-dd'),
        endDate: format(periodEndDate, 'yyyy-MM-dd'),
        period: `${format(periodStartDate, 'MMM d')} - ${format(periodEndDate, 'MMM d')}`,
      })
    }
  }
  return result
}

const generateAnnualBillingPeriods = earliestDate => {
  const result = []
  const startDate = parseISO(earliestDate)
  const startYear = startDate.getFullYear()
  const currentYear = new Date().getFullYear()

  for (let year = startYear; year <= currentYear; year++) {
    const periodStartDate = startOfYear(new Date(year, 0, 1))
    const periodEndDate = endOfYear(periodStartDate)

    result.push({
      label: `${year}`,
      startDate: format(periodStartDate, 'yyyy-MM-dd'),
      endDate: format(periodEndDate, 'yyyy-MM-dd'),
      period: `${format(periodStartDate, 'MMM d')} - ${format(periodEndDate, 'MMM d')}`,
    })
  }
  return result
}

export const STATEMENT_MONTHLY_PERIOD = 'monthly'
export const STATEMENT_QUARTERLY_PERIOD = 'quarterly'
export const STATEMENT_ANNUAL_PERIOD = 'annually'

export const STATEMENT_TYPE_QUARTERLY = 'QUARTERLY'
export const STATEMENT_TYPE_ANNUAL = 'ANNUALLY'
export const STATEMENT_TYPE_MONTHLY = 'MONTHLY'

export const generateBillingPeriods = (earliestDate, periodType) => {
  if (periodType === STATEMENT_MONTHLY_PERIOD) return generateMonthlyBillingPeriods(earliestDate)
  if (periodType === STATEMENT_QUARTERLY_PERIOD) return generateQuarterlyBillingPeriods(earliestDate)
  if (periodType === STATEMENT_ANNUAL_PERIOD) return generateAnnualBillingPeriods(earliestDate)
  return []
}

export const getTerm = period => {
  if (period === STATEMENT_MONTHLY_PERIOD) return 1
  if (period === STATEMENT_ANNUAL_PERIOD) return 12
  return 3 // quarterly is the default case
}

export const periodOptions = [
  { key: STATEMENT_MONTHLY_PERIOD, label: 'Monthly', value: STATEMENT_MONTHLY_PERIOD },
  { key: STATEMENT_QUARTERLY_PERIOD, label: 'Quarterly', value: STATEMENT_QUARTERLY_PERIOD },
  { key: STATEMENT_ANNUAL_PERIOD, label: 'Annual', value: STATEMENT_ANNUAL_PERIOD },
]

export const formatStatementQuarterlyPeriod = data => {
  if (data.type !== STATEMENT_TYPE_QUARTERLY) return null

  const startDate = parseISO(data.startDate)
  const endDate = parseISO(data.endDate)
  const quarter = getQuarter(startDate)
  const year = format(startDate, 'yyyy')

  return `Q${quarter} ${year} | ${format(startDate, 'MMM d')} - ${format(endDate, 'MMM d')}`
}

export const formatStatementAnnualPeriod = data => {
  if (data.type !== STATEMENT_TYPE_ANNUAL) return null

  const startDate = parseISO(data.startDate)
  const year = format(startDate, 'yyyy')

  return `Annual ${year}`
}

export const formatStatementMonthlyPeriod = data => {
  if (data.type !== STATEMENT_TYPE_MONTHLY) return null

  const startDate = parseISO(data.startDate)
  return format(startDate, 'MMMM yyyy')
}

export const formatStatementPeriod = data => {
  if (data.type === STATEMENT_TYPE_QUARTERLY) {
    return formatStatementQuarterlyPeriod(data)
  }

  if (data.type === STATEMENT_TYPE_ANNUAL) {
    return formatStatementAnnualPeriod(data)
  }

  if (data.type === STATEMENT_TYPE_MONTHLY) {
    return formatStatementMonthlyPeriod(data)
  }

  return null
}

export const STATEMENT_TYPE_PRIORITY = {
  [STATEMENT_TYPE_ANNUAL]: 1,
  [STATEMENT_TYPE_QUARTERLY]: 2,
  [STATEMENT_TYPE_MONTHLY]: 3,
}

export const groupStatementsByYear = statements => {
  const sortedStatements = orderBy(statements, [statement => STATEMENT_TYPE_PRIORITY[statement.type], 'startDate'], ['asc', 'desc'])
  return groupBy(sortedStatements, statement => new Date(statement.startDate).getFullYear())
}

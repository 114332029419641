import routes from 'router/routes'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { isStatementsFeatureAvailable } from '../../data/launch-darkly/featureSelectors'

const { app } = routes

const menuItems = {
  home: {
    title: 'Home',
    route: app.home,
  },
  invoices: {
    title: 'Invoices',
    route: app.invoices,
  },
  payments: {
    title: 'Payments',
    route: app.payments,
  },
  statements: {
    title: 'Statements',
    route: app.statements,
  },
}

export const MENU_ITEMS = [menuItems.home, menuItems.invoices, menuItems.payments, menuItems.statements]

export const useFilteredMenuItems = () => {
  const flags = useFlags()
  const isStatementsAvailable = isStatementsFeatureAvailable({ flags })

  return MENU_ITEMS.filter(item => {
    if (item.route === app.statements) {
      return isStatementsAvailable
    }
    return true
  })
}

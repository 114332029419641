import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { noop, get} from 'lodash'
import { blobToBase64, getStatementBlob } from 'utils/file'
import PdfViewer from 'components/drawer/pdf-viewer/PdfViewer'

const StatementDrawer = ({ open = false, accountName = '', statementNumber = '', onClose = noop }) => {
  const [pdfData, setPdfdata] = useState(undefined)
  const statementFileName = ['Statement', accountName, statementNumber].filter(Boolean).join(' - ')
  const [isLoading, setIsLoading] = useState(false)

  const businessId = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.businessId'), shallowEqual)

  const getStatement = () => {
    setIsLoading(true)
    getStatementBlob(statementNumber, businessId)
      .then(blobToBase64)
      .then(base64 => {
        setPdfdata(base64.split(',')[1])
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (!statementNumber || open === false || !businessId) return
    getStatement()
  }, [statementNumber, businessId, open])

  const handleDownloadClick = () => {
    const link = document.createElement('a')
    link.href = `data:application/pdf;base64,${pdfData}`
    link.download = `${statementNumber || 'statement'}.pdf`
    link.click()
  }

  return (
    <PdfViewer
      open={open}
      isLoading={isLoading}
      type="statement"
      fileName={statementFileName}
      title={statementFileName}
      pdfData={pdfData}
      onClose={() => {
        setPdfdata(undefined)
        onClose()
      }}
      onDownload={handleDownloadClick}
    />
  )
}

StatementDrawer.propTypes = {
  open: PropTypes.bool,
  accountName: PropTypes.string,
  statementNumber: PropTypes.number,
  onClose: PropTypes.func,
}

export default StatementDrawer
